@import "../colors.scss";

.modal {
  transition: opacity 200ms ease-in-out;
  opacity: 1;
  width: 80%;
  max-width: 400px;
}

.form {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  & :global(.link) {
    align-self: center;
  }
}

.separator {
  opacity: 1;
  background-color: $primary;
  margin: 8px 0;
}

.switch {
  align-self: center;
  font-size: 14px;
  font-weight: 500;
}