@import '../../colors.scss';

$transparent-background: rgba(0, 0, 0, 0.4);

.background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 100;

  background-color: $transparent-background;

  width: 100vw;
  height: 100vh;
}

.modal {
  max-height: 90vh;

  .header,
  .content {
    padding: 1rem 1.25rem;
  }
}

.header {
  position: relative;
  border-bottom: 0.0625rem solid $grey;

  h4 {
    margin: 0;
    text-align: left;
    color: $black;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .warning-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $warning;

    & .icon {
      width: 1.75rem;
    }
  }

  .close {
    position: absolute;
    top: 4px;
    right: 5px;

    border: unset;
    background-color: transparent;
    color: $secondary;
  }
}

.content {
  overflow-y: auto;
}

.modal-body {
  line-height: normal;

  .form-group {
    margin-top: 1rem;

    & > div {
      min-height: 0;
    }
  }
}

.footer {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 2rem;

  &::before {
    position: absolute;
    top: -1rem;
    right: -20px;
    left: -20px;
    border-top: 1px solid $grey;
    content: '';
  }
}
