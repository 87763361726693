.spinner {
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-width: 0.3rem;
}

.message {
  padding: 8px 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
