@import "../colors.scss";

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 10px;
  grid-gap: 8px 10px;
}

.row {
  display: flex;
  flex-direction: row;
  grid-gap: 8px 10px;
}

.submit {
  width: 150px;
  margin-left: auto;
}