@import '../../colors.scss';

$shadow: rgba($black, 0.2);

.card {
  &:global(.card) {
    border-radius: 0.5rem;
    box-shadow: 0 1.375rem 2.125rem 0 $shadow;
  }
}
