@import "../colors.scss";

.product {
  border: 2px solid $primary;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 10px;
  margin: 10px 0;

  & .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
  }

  & h1 {
    font-size: 14px;
    font-weight: 500;
  }

  & .description {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    & h5 {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
    }

    & .icon {
      margin-left: auto;
    }

    & .text {
      font-size: 14px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  & hr {
    margin: 8px 0;
  }
}

.modal {
  transition: opacity 200ms ease-in-out;
  opacity: 1;
  width: 80%;
  max-width: 800px;

  & .text {
    font-size: 14px;
  }
}