@import '../../colors.scss';

.button {
  border: 0;
  background-color: transparent;
  padding: 0;
  color: $primary;

  &:hover {
    color: $primary-dark;
  }

  &:focus {
    outline: none;
  }
}
