@import "../colors.scss";

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 1080px;
  margin: auto;
  padding: 16px 20px;

  & > * {
    padding: 0;
  }

  & > h1, h2 {
    align-self: flex-start;
  }
}

.category {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  grid-gap: 8px 10px;
}

.card {
  width: 200px;
  text-align: center;
  padding: 5px;
}

.image {
  margin: auto;
  width: 193px;
  height: 193px;
  display: flex;
  border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    border: 2px solid $primary;
  }

  & > * {
    margin: auto;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .card {
    width: 150px;
  }
  .image {
    width: 150px;
    height: 150px;
  }
}

.name {
  color: $black;
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  margin: 4px 0;
  white-space: pre-line;
  overflow-wrap: break-word;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pagination {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  flex-wrap: wrap;
  margin: 16px 0;
  grid-gap: 10px;

  & > * {
    padding: 0;
  }
}