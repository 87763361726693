@import "../colors.scss";

.content {
  padding: 16px 20px;
  width: 95%;
  max-width: 1200px;
  margin: auto;
}

.card {
  border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 10px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  grid-gap: 8px 10px;

  & .signup {
    display: inline-flex;
    font-size: 14px;
    grid-gap: 4px;
  }
}