@import '../../colors.scss';

$primary-faded: rgba(14, 105, 55, 0.3);
$primary-disabled: rgba(14, 105, 55, 0.3);

$cancel-faded: #b4b9c0;
$cancel-disabled: #d2d5d9;

$special-faded: #ffdb99;
$special-disabled: #ffe9c1;

$error-faded: #f1a9a9;
$error-disabled: #f6cbcb;

$success-faded: #a1e1c9;
$success-disabled: #c7eddf;

$button-padding: 10px;

.button {
  position: relative;
  border: solid 0.0625rem $primary;
  background-color: $primary;

  padding: 0 $button-padding;
  min-width: 2rem;
  min-height: 2rem;
  line-height: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;

  &.round {
    border-radius: 50%;
    padding: 0;
    min-width: 24px;
    height: 24px;
    min-height: 24px;

    .icon-left {
      width: auto;

      :global(.svg-inline--fa) {
        width: 12px;
        height: 12px;
      }
    }

    .spinner {
      bottom: 3px;
      left: 3px;
    }
  }

  &.fill {
    min-width: 100%;

    .content {
      margin: auto;
    }
  }

  .hide {
    visibility: hidden;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    margin: 0 0.25rem;
  }
}

.icon-left {
  padding-right: 0.25rem;
}

.icon-right {
  padding-left: 0.25rem;
}

.primary {
  color: $white;

  &:hover:not(.secondary) {
    color: $white;

    &:enabled {
      background-color: $primary-dark;
    }
  }

  &:active,
  &:focus {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.1875rem $primary-faded;
    }
  }

  &:disabled {
    border-color: $primary-faded;
    background-color: $primary-disabled;
    cursor: not-allowed;
  }
}

.secondary {
  border-color: $primary;
  background-color: $white;
  color: $primary;

  &:hover {
    border-color: $primary-dark;
    background-color: $white;
    color: $primary-dark;
  }

  &:disabled {
    border-color: $primary-disabled;
    background-color: $white;
    color: $primary-disabled;
  }

  &.special {
    border-color: $special;
    background-color: $white;
    color: $special;

    &:hover {
      border-color: $special-dark;
      color: $special-dark;
    }

    &:disabled {
      border-color: $special-disabled;
      color: $special-disabled;
    }
  }

  &.warning {
    border-color: $warning;
    background-color: $white;
    color: $warning;

    &:hover {
      border-color: $warning-dark;
      color: $warning-dark;
    }

    &:disabled {
      border-color: $error-disabled;
      color: $error-disabled;
    }
  }

  &.success {
    border-color: $success;
    background-color: $white;
    color: $success;

    &:hover {
      border-color: $success-dark;
      color: $success-dark;
    }

    &:disabled {
      border-color: $success-disabled;
      color: $success-disabled;
    }
  }
}

.cancel {
  border-color: $secondary;
  background-color: $white;
  color: $secondary;

  &:hover {
    border-color: $black;
    color: $black;
  }

  &:active,
  &:focus {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.1875rem $cancel-faded;
    }
  }

  &:disabled {
    border-color: $cancel-disabled;
    color: $cancel-disabled;
  }
}

.special {
  border-color: $special;
  background-color: $special;
  color: $white;

  &:hover:not(.secondary) {
    color: $white;

    &:enabled {
      background-color: $special-dark;
    }
  }

  &:active,
  &:focus {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.1875rem $special-faded;
    }
  }

  &:disabled {
    border-color: $special-faded;
    background-color: $special-disabled;
    color: $white;
  }
}

.warning {
  border-color: $error;
  background-color: $error;
  color: $white;

  &:hover:not(.secondary) {
    color: $white;

    &:enabled {
      background-color: $error-dark;
    }
  }

  &:active,
  &:focus {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.1875rem $error-faded;
    }
  }

  &:disabled {
    border-color: $error-faded;
    background-color: $error-disabled;
    color: $white;
  }
}

.success {
  border-color: $success;
  background-color: $success;
  color: $white;

  &:hover:not(.secondary) {
    color: $white;

    &:enabled {
      background-color: $success-dark;
    }
  }

  &:active,
  &:focus {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.1875rem $success-faded;
    }
  }

  &:disabled {
    border-color: $success-faded;
    background-color: $success-disabled;
    color: $white;
  }
}

.spinner {
  position: absolute;
  left: calc(50% - #{$button-padding});
}
