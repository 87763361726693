$primary: #0e6937;
$primary-light: rgba(14, 105, 55, 0.3);
$primary-lighter: rgba(14, 105, 55, 0.3);
$primary-dark: #094a27;

$secondary: #6a7381;
$black: #262626;
$grey: #d8d8d8;
$grey-light: #f1f0f0;
$off-white: #fdfcfc;
$white: #fff;

$success: #43c393;
$success-light: #d9f9ed;
$success-dark: #038d59;

$special: #ffb833;
$special-light: #fff4e1;
$special-dark: #e39000;

$error: #e45454;
$error-light: #ffe0e0;
$error-dark: #9b0e0e;

$warning: #ff7e4a;
$warning-light: #ffe2d6;
$warning-dark: #da4002;

$shadow: #cfd2d6;

$purple: #ad45c8;
