@import "colors.scss";

body {
  font-family: 'Inter';
  font-size: 12px;
  line-height: 1.5;
  color: $black;
  text-align: left;
}

a {
  text-decoration: none;
}

h1 {
  font-weight: 500;
  font-size: 21px;
  text-transform: uppercase;
}

h2 {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: $primary;
  border-bottom: solid 1px $primary;
  width: 100%;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  color: $primary;
}

.content {
  margin: 48px 0;
  height: calc(100vh - 96px);
  overflow-y: auto;
}

.App-logo {
  width: 132.17px;
  padding: 10px 0;
}

.legal-content {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1200px;
  margin: auto;
  padding: 16px 20px;
  grid-gap: 8px 10px;
  font-size: 14px;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  height: 48px;
  left: 0;
  right: 0;
  top: 0;
  background: $off-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  padding: 0 20px;
}

.App-user {
  position: relative;
  margin: 8px 10px;
  font-size: 20px;

  & .user-menu {
    position: absolute;
    top: 30px;
    right: 0;
    min-width: 150px;

    & .title {
      border-bottom: 1px solid $grey;
      padding: 8px 10px;
    }

    & .card-content {
      padding: 8px 10px;

      & > button {
        border: 0
      }
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 4px;

  & > select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  & > label {
    color: $secondary;
    font-size: 14px;
  }

  & .form-control {
    font-size: 14px;
    width: 100%;
    padding: 8px 10px;
  }
}

.form-lieu, .form-textArea {
  width: 100%;
}

.link-back {
  text-decoration: none;
  color: $primary;

  &:hover {
    color: $primary-dark;
  }
}

.link {
  font-size: 14px;
  font-weight: 500;
  color: $primary;

  &:hover {
    color: $primary-dark;
    text-decoration: underline;
    cursor: pointer;
  }
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: $primary;
  color: $white;
  padding: 0 20px;
  font-size: 14px;

  & a {
    color: $white;

    &:hover {
      color: $off-white;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}